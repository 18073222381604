enum AchievementType {
  // TODO: generic COMPLETED_LEVELS achievement with an array of values in achievementInfo
  SOLVED_LEVELS_100 = 'COMPLETED_LEVELS_100',
  SOLVED_LEVELS_500 = 'COMPLETED_LEVELS_500',
  SOLVED_LEVELS_1000 = 'COMPLETED_LEVELS_1000',
  SOLVED_LEVELS_2000 = 'COMPLETED_LEVELS_2000',
  SOLVED_LEVELS_3000 = 'COMPLETED_LEVELS_3000',
  SOLVED_LEVELS_4000 = 'COMPLETED_LEVELS_4000',
  SOLVED_LEVELS_5000 = 'COMPLETED_LEVELS_5000',
  PLAYER_RANK_KINDERGARTEN = 'PLAYER_RANK_KINDERGARTEN',
  PLAYER_RANK_ELEMENTARY = 'PLAYER_RANK_ELEMENTARY',
  PLAYER_RANK_JUNIOR_HIGH = 'PLAYER_RANK_JUNIOR_HIGH',
  PLAYER_RANK_HIGH_SCHOOL = 'PLAYER_RANK_HIGH_SCHOOL',
  PLAYER_RANK_BACHELORS = 'PLAYER_RANK_BACHELORS',
  PLAYER_RANK_MASTERS = 'PLAYER_RANK_MASTERS',
  PLAYER_RANK_PHD = 'PLAYER_RANK_PHD',
  PLAYER_RANK_PROFESSOR = 'PLAYER_RANK_PROFESSOR',
  PLAYER_RANK_GRANDMASTER = 'PLAYER_RANK_GRANDMASTER',
  PLAYER_RANK_SUPER_GRANDMASTER = 'PLAYER_RANK_SUPER_GRANDMASTER',
  CREATOR_CREATED_1_LEVEL = 'CREATOR_CREATED_1_LEVEL',
  CREATOR_CREATED_5_LEVELS = 'CREATOR_CREATED_5_LEVELS',
  CREATOR_CREATED_10_LEVELS = 'CREATOR_CREATED_10_LEVELS',
  CREATOR_CREATED_25_LEVELS = 'CREATOR_CREATED_25_LEVELS',
  CREATOR_CREATED_50_LEVELS = 'CREATOR_CREATED_50_LEVELS',
  CREATOR_CREATED_100_LEVELS = 'CREATOR_CREATED_100_LEVELS',
  CREATOR_CREATED_200_LEVELS = 'CREATOR_CREATED_200_LEVELS',
  CREATOR_CREATED_300_LEVELS = 'CREATOR_CREATED_300_LEVELS',
  REVIEWED_1_LEVEL = 'REVIEWED_1_LEVEL',
  REVIEWED_25_LEVELS = 'REVIEWED_25_LEVELS',
  REVIEWED_100_LEVELS = 'REVIEWED_100_LEVELS',
  REVIEWED_500_LEVELS = 'REVIEWED_500_LEVELS',
  REVIEWED_1000_LEVELS = 'REVIEWED_1000_LEVELS',
  REVIEWED_3000_LEVELS = 'REVIEWED_3000_LEVELS',
  MULTIPLAYER_1_GAME_PLAYED = 'MULTIPLAYER_1_GAME_PLAYED',
  MULTIPLAYER_BULLET_5_GAME_PLAYED = 'MULTIPLAYER_BULLET_5_GAME_PLAYED',
  MULTIPLAYER_BLITZ_5_GAME_PLAYED = 'MULTIPLAYER_BLITZ_5_GAME_PLAYED',
  MULTIPLAYER_RAPID_5_GAME_PLAYED = 'MULTIPLAYER_RAPID_5_GAME_PLAYED',
  MULTIPLAYER_CLASSICAL_5_GAME_PLAYED = 'MULTIPLAYER_CLASSICAL_1_GAME_PLAYED',
  CREATOR_CREATED_1_HIGH_QUALITY_LEVEL = 'CREATOR_CREATED_1_HIGH_QUALITY_LEVEL',
  CREATOR_CREATED_10_HIGH_QUALITY_LEVELS = 'CREATOR_CREATED_10_HIGH_QUALITY_LEVELS',
  CREATOR_CREATED_25_HIGH_QUALITY_LEVELS = 'CREATOR_CREATED_25_HIGH_QUALITY_LEVELS',
  RECORD_AFTER_1_YEAR = 'RECORD_AFTER_1_YEAR',
  COMMENT_1 = 'COMMENT_1',
  COMMENT_5 = 'COMMENT_5',
  COMMENT_10 = 'COMMENT_10',
  COMMENT_25 = 'COMMENT_25',
  WELCOME = 'WELCOME',
}

export default AchievementType;
